import { useCallback, useEffect, useRef } from 'react'
import { t } from 'i18next'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import {
  Box,
  Skeleton,
  Tooltip,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { ITableColumn, ITheme } from '@pkg/sokarUI'
import { parsePhoneNumber } from '@shared/utils/formatters'
import { useSearchUserQuery } from '@features/users/usersApiSlice'
import useOwnUser from '@hooks/useOwnUser'
import { IUser } from '@pkg/users/models'
import styles from './employeesManager.module.scss'
import SideDialog from '@lib/SideDialog'
import CreateEmployeeDialog from './components/CreateEmployeeDialog'
import EmployeeDialog from './components/EmployeeDialog'
import ContentList from '@lib/ContentList'
import ConfirmationDialog, { IConfirmationDialogRef } from '@lib/ConfirmationDialog'

import AddIcon from '@mui/icons-material/Add'
import ReplayIcon from '@mui/icons-material/Replay'
import EmployeeCard from './components/EmployeeCard'

const columns: ITableColumn[] = [
  {
    label: t('Management:employeeTable.name'),
    dataKey: 'firstName',
    isSortable: true,
  },
  {
    label: t('Management:employeeTable.surname'),
    dataKey: 'lastName',
    isSortable: true,
  },
  {
    label: t('Management:employeeTable.phone'),
    dataKey: 'phone',
    isSortable: true,
  },
  {
    label: 'e-mail',
    dataKey: 'email',
    isSortable: true,
  },
]

const EmployeeManager: React.FC = () => {
  const appTheme: ITheme = useTheme()
  // @ts-ignore
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const tableRef = useRef<ITableRef>(null)

  const cellRenderer = (column: ITableColumn, row: IUser): JSX.Element => {
    if (column.dataKey === 'phone')
      return (
        <Typography
          sx={{
            color:
              row.disabledAt != null ? appTheme.palette.error.main : appTheme.palette.text.primary,
          }}
        >
          {parsePhoneNumber(row[column.dataKey])}
        </Typography>
      )
    if (column.dataKey === 'firstName')
      return (
        <Typography
          sx={{
            color:
              row.disabledAt != null ? appTheme.palette.error.main : appTheme.palette.text.primary,
          }}
        >
          {row[column.dataKey]}
        </Typography>
      )
    if (column.dataKey === 'lastName')
      return (
        <Typography
          sx={{
            color:
              row.disabledAt != null ? appTheme.palette.error.main : appTheme.palette.text.primary,
          }}
        >
          {row[column.dataKey]}
        </Typography>
      )
    if (column.dataKey === 'email')
      return (
        <Typography
          sx={{
            color:
              row.disabledAt != null ? appTheme.palette.error.main : appTheme.palette.text.primary,
          }}
        >
          {row[column.dataKey]}
        </Typography>
      )

    return (
      <Typography
        sx={{
          color:
            row.disabledAt != null ? appTheme.palette.error.main : appTheme.palette.text.primary,
        }}
      >
        {row[column.dataKey as keyof IUser]}
      </Typography>
    )
  }

  const { companyId: ownCompanyId } = useOwnUser()
  const {
    data: employees,
    isFetching: isEmployeesFetching,
    refetch: refetchEmployees,
  } = useSearchUserQuery({ companyId: ownCompanyId }, { skip: !ownCompanyId })

  const createDialogRef = useRef<any>(null)
  const handleCreateDialogOpen = (): void => {
    createDialogRef.current?.open({})
  }
  const handleCreateEmployeeDialogClose = (): void => {
    createDialogRef.current?.close()
  }

  const employeeDialogRef = useRef<any>(null)
  const handleEmployeeDialogOpen = useCallback((row: IUser): void => {
    employeeDialogRef.current?.open(row)
  }, [])
  const handleEmployeeDialogClose = useCallback((): void => {
    employeeDialogRef.current?.close()
  }, [])

  const confirmationDialogRef = useRef<IConfirmationDialogRef>(null)

  const FreeActions: JSX.Element = (
    <>
      <Tooltip
        title={t('Management:employeeTable.add')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleCreateDialogOpen()}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Management:employeeTable.refresh')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={refetchEmployees}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const listRef = useRef<any>(null)
  const getProperComponent = (): JSX.Element => {
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          style={{ height: 'calc(100dvh - 60px)' }}
          openDialog={handleEmployeeDialogOpen}
          listComponent={EmployeeCard}
        />
      )
    }
    return (
      <ContentTable
        ref={tableRef}
        style={{ height: 'calc(100dvh - 120px)' }}
        freeActions={FreeActions}
        cellRenderer={cellRenderer}
        openDialog={handleEmployeeDialogOpen}
      />
    )
  }

  useEffect(() => {
    if (!!employees) {
      if (isMobile) {
        listRef.current?.open(employees)
      } else {
        tableRef.current?.open(employees, columns)
      }
    }
  }, [employees, columns, isEmployeesFetching, isMobile])

  if (!employees) {
    return (
      <Box className={styles.loader}>
        {Array(12)
          .fill(null)
          .map((_, index) => (
            <Skeleton
              key={index}
              animation={'wave'}
            />
          ))}
      </Box>
    )
  }

  return (
    <>
      {getProperComponent()}
      <SideDialog
        ref={employeeDialogRef}
        title={t('Management:employeeDialog.title')}
      >
        <EmployeeDialog
          handleClose={handleEmployeeDialogClose}
          elementRef={employeeDialogRef}
          confirmationRef={confirmationDialogRef}
          refetch={refetchEmployees}
        />
      </SideDialog>
      <SideDialog
        ref={createDialogRef}
        title={t('Management:createEmployee.title')}
      >
        <CreateEmployeeDialog
          handleClose={handleCreateEmployeeDialogClose}
          refetch={refetchEmployees}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default EmployeeManager
