import * as Yup from 'yup'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

export const userLoginValidationSchema = Yup.object().shape({
  username: Yup.string()
    .required(t('Root:auth.validatorResponses.usernameRequired') ?? '')
    .min(5, t('Root:auth.validatorResponses.usernameMinConstraint', { characters: 5 }) ?? '')
    .max(24, t('Root:auth.validatorResponses.usernameMaxConstraint', { characters: 24 }) ?? ''),
  password: Yup.string()
    .required(t('Root:auth.validatorResponses.passwordRequired') ?? '')
    .min(8, t('Root:auth.validatorResponses.passwordMinConstraint', { characters: 8 }) ?? '')
    .max(124, t('Root:auth.validatorResponses.passwordMaxConstraint', { characters: 124 }) ?? ''),
})

export const changePasswordValidation = Yup.object().shape({
  oldPassword: Yup.string()
    .required(t('Root:auth.validatorResponses.passwordRequired') ?? '')
    .min(8, t('Root:auth.validatorResponses.passwordMinConstraint', { characters: 8 }) ?? '')
    .max(24, t('Root:auth.validatorResponses.passwordMaxConstraint', { characters: 124 }) ?? ''),

  newPassword: Yup.string()
    .required(t('Root:auth.validatorResponses.passwordRequired') ?? '')
    .min(8, t('Root:auth.validatorResponses.passwordMinConstraint', { characters: 5 }) ?? '')
    .max(124, t('Root:auth.validatorResponses.passwordMaxConstraint', { characters: 124 }) ?? ''),
})

export const userSchema = Yup.object().shape({
  firstName: Yup.string().required(() => t('Root:auth.validatorResponses.firstNameRequired')),
  lastName: Yup.string().required(() => t('Root:auth.validatorResponses.lastNameRequired')),
  email: Yup.string()
    .email(() => t('Root:auth.validatorResponses.emailInvalid'))
    .required(() => t('Root:auth.validatorResponses.emailRequired')),
  phone: Yup.string().required(() => t('Root:auth.validatorResponses.phoneRequired')),
})

export const registerSchema = Yup.object().shape({
  user: Yup.object().shape({
    firstName: Yup.string().required(() => t('Root:auth.validatorResponses.firstNameRequired')),
    lastName: Yup.string().required(() => t('Root:auth.validatorResponses.lastNameRequired')),
    email: Yup.string()
      .email(() => t('Root:auth.validatorResponses.invalidEmail'))
      .required(() => t('Root:auth.validatorResponses.emailRequired')),
    username: Yup.string().required(() => t('Root:auth.validatorResponses.usernameRequired')),
    password: Yup.string()
      .min(8, () => t('Root:auth.validatorResponses.passwordMin'))
      .required(() => t('Root:auth.validatorResponses.passwordRequired'))
      .matches(/[A-Z]/, () => t('Root:auth.validatorResponses.passwordUppercase'))
      .matches(/[a-z]/, () => t('Root:auth.validatorResponses.passwordLowercase'))
      .matches(/[0-9]/, () => t('Root:auth.validatorResponses.passwordDigit'))
      .matches(/[\W_]/, () => t('Root:auth.validatorResponses.passwordSpecialChar')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], () =>
        t('Root:auth.validatorResponses.confirmPasswordMatch'),
      )
      .required(() => t('Root:auth.validatorResponses.confirmPasswordRequired')),
    phone: Yup.string()
      .required(() => t('Root:auth.validatorResponses.phoneRequired'))
      .min(6, () => t('Root:auth.validatorResponses.phoneMinLength')),
  }),
  vatNumber: Yup.string().required(() => t('Root:auth.validatorResponses.vatNumberRequired')),
  companyType: Yup.string().required(() => t('Root:auth.validatorResponses.companyTypeRequired')),
  terms: Yup.boolean().oneOf([true], () =>
    t('Root:auth.validatorResponses.termsAcceptanceRequired'),
  ),
})

export const resetSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, () => t('Root:auth.validatorResponses.passwordMin'))
    .required(() => t('Root:auth.validatorResponses.passwordRequired'))
    .matches(/[A-Z]/, () => t('Root:auth.validatorResponses.passwordUppercase'))
    .matches(/[a-z]/, () => t('Root:auth.validatorResponses.passwordLowercase'))
    .matches(/[0-9]/, () => t('Root:auth.validatorResponses.passwordDigit'))
    .matches(/[\W_]/, () => t('Root:auth.validatorResponses.passwordSpecialChar')),
  passwordCheck: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], () =>
      t('Root:auth.validatorResponses.confirmPasswordMatch'),
    )
    .required(() => t('Root:auth.validatorResponses.confirmPasswordRequired')),
})

export const registerEmployeeSchema = Yup.object().shape({
  username: Yup.string()
    .required(() => t('Management:validatorResponses.usernameRequiredConstraint'))
    .min(8, () => t('Management:validatorResponses.usernameMinConstraint')),
  firstName: Yup.string().required(() =>
    t('Management:validatorResponses.firstNameRequiredConstraint'),
  ),
  lastName: Yup.string().required(() =>
    t('Management:validatorResponses.lastNameRequiredConstraint'),
  ),
  email: Yup.string()
    .email(() => t('Management:validatorResponses.emailFormatConstraint'))
    .required(() => t('Management:validatorResponses.emailRequiredConstraint')),
  phone: Yup.string().required(() => t('Management:validatorResponses.phoneRequiredConstraint')),
  password: Yup.string()
    .min(8, () => t('Root:auth.validatorResponses.passwordMin'))
    .required(() => t('Root:auth.validatorResponses.passwordRequired'))
    .matches(/[A-Z]/, () => t('Root:auth.validatorResponses.passwordUppercase'))
    .matches(/[a-z]/, () => t('Root:auth.validatorResponses.passwordLowercase'))
    .matches(/[0-9]/, () => t('Root:auth.validatorResponses.passwordDigit'))
    .matches(/[\W_]/, () => t('Root:auth.validatorResponses.passwordSpecialChar')),
  passwordCheck: Yup.string()
    .oneOf([Yup.ref('password'), null], () =>
      t('Root:auth.validatorResponses.confirmPasswordMatch'),
    )
    .required(() => t('Root:auth.validatorResponses.confirmPasswordRequired')),
})
