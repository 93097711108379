import { useCallback, useEffect, useRef } from 'react'
import { t } from 'i18next'

import { ITableColumn } from '@pkg/sokarUI'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import { useSearchAsksQuery } from '@features/users/usersApiSlice'
import { parsePhoneNumber } from '@shared/utils/formatters'
import ContentList from '@lib/ContentList'
import SideDialog from '@lib/SideDialog'
import RegisterAskDialog from './components/registerAskDialog'

import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { IRegisterAsk } from '@pkg/users/models'
import RequestCard from './components/requestCard'

const columns: ITableColumn[] = [
  {
    label: t('Management:registerTable.taxId'),
    dataKey: 'vatNumber',
    isSortable: true,
  },
  {
    label: t('Management:registerTable.name'),
    dataKey: 'firstName',
    isSortable: false,
  },
  {
    label: t('Management:registerTable.surname'),
    dataKey: 'lastName',
    isSortable: false,
  },
  {
    label: t('Management:registerTable.phone'),
    dataKey: 'phone',
    isSortable: false,
  },
  {
    label: 'e-mail',
    dataKey: 'email',
    isSortable: false,
  },
]

const AccessRequestManager: React.FC = () => {
  const appTheme = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const cellRenderer = (column: ITableColumn, row: any): JSX.Element => {
    if (column.dataKey === 'phone')
      return <Typography>{parsePhoneNumber(row.user[column.dataKey])}</Typography>
    if (column.dataKey === 'firstName') return <Typography>{row.user[column.dataKey]}</Typography>
    if (column.dataKey === 'lastName') return <Typography>{row.user[column.dataKey]}</Typography>
    if (column.dataKey === 'email') return <Typography>{row.user[column.dataKey]}</Typography>

    return row[column.dataKey]
  }

  const { data: registerAsks, refetch: refetchAsks } = useSearchAsksQuery({
    approved: false,
    rejected: false,
  })

  const tableRef = useRef<ITableRef>(null)
  const listRef = useRef<any>(null)
  useEffect(() => {
    if (isMobile) listRef.current?.open(registerAsks?.asks)
    tableRef.current?.open(registerAsks?.asks, columns)
  }, [registerAsks, columns, isMobile])

  const getProperComponent = (): JSX.Element => {
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          openDialog={handleAskDialogOpen}
          listComponent={RequestCard}
        />
      )
    }
    return (
      <ContentTable
        ref={tableRef}
        style={{ height: 'calc(100dvh - 120px)' }}
        cellRenderer={cellRenderer}
        openDialog={handleAskDialogOpen}
      />
    )
  }

  const askDialogRef = useRef<any>(null)
  const handleAskDialogOpen = useCallback((row: IRegisterAsk) => {
    askDialogRef.current?.open(row)
  }, [])
  const handleAskDialogClose = useCallback(() => {
    askDialogRef.current?.close()
  }, [])

  return (
    <>
      {getProperComponent()}
      <SideDialog
        ref={askDialogRef}
        title={t('Management:registerAskDialog.title')}
      >
        <RegisterAskDialog
          refetch={refetchAsks}
          handleClose={handleAskDialogClose}
        />
      </SideDialog>
    </>
  )
}

export default AccessRequestManager
