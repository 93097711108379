import { useRef, useEffect, useState, useCallback } from 'react'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { useTheme } from '@emotion/react'
import { useGetLoadQuery, useSearchLoadsQuery } from '@/features/loads/loadsApiSlice'
import useRefetchWithAlert from '@/hooks/useRefetchWithAlert'
import ConfirmationDialog from '@lib/ConfirmationDialog'

import ContentList from '@lib/ContentList'
import SideDialog from '@lib/SideDialog'
import ClusteredMap from '@lib/ClusteredMap'
import LoadDialog from './components/LoadDialog'
import LoadCard from './components/LoadCard'
import CurrentLoadsOptionsCard from './components/CurrentLoadsOptionCard'
import CreateLoadDialog from './components/CreateLoadDialog'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import { ILoad } from '@pkg/loads/models'
import { ITableColumn } from '@pkg/sokarUI'
import TeamCell from './components/TeamCell'
import { getFileAlertColour } from '@shared/utils/formatters'
import appConfig from '@/config'
import './style.scss'

import { Tooltip, IconButton, Typography, Skeleton, useMediaQuery } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import ReplayIcon from '@mui/icons-material/Replay'
import WarningIcon from '@mui/icons-material/Warning'

const columns: ITableColumn[] = [
  {
    label: t('Fleet:currentLoads.team'),
    dataKey: 'team',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.number'),
    dataKey: 'externalId',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.pickupLocation'),
    dataKey: 'pickupLocation',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.pickupDate'),
    dataKey: 'pickupDate',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.deliveryLocation'),
    dataKey: 'deliveryLocation',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.deliveryDate'),
    dataKey: 'deliveryDate',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.status'),
    dataKey: 'statusName',
    isSortable: false,
  },
]

const CurrentLoads: React.FC = () => {
  const appTheme: any = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const [isMapView, setIsMapView] = useState<boolean>(false)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const [isSearched, setIsSearched] = useState<boolean>(false)

  const loadDialogRef = useRef<any>(null)
  const handleLoadDialogClose = useCallback((): void => {
    loadDialogRef.current?.close()
  }, [])
  const handleLoadDialogOpen = useCallback((row: ILoad): void => {
    loadDialogRef.current?.open(row)
  }, [])

  const {
    data: loads,
    isSuccess: isLoadsSuccess,
    refetch: refetchLoads,
  } = useGetLoadQuery(
    {
      published: false,
      archived: false,
      tags: 'carrier-load',
    },
    { skip: isSearched },
  )
  const refresh = useRefetchWithAlert(refetchLoads)

  const { data: searchedLoads, isSuccess: isSearchSuccess } = useSearchLoadsQuery(
    { phrase: searchPhrase, archived: false, published: false, tags: 'carrier-load' },
    {
      skip: !isSearched,
    },
  )

  const handleSearch = (phrase: string, isSearched: boolean): void => {
    setSearchPhrase(phrase)
    setIsSearched(isSearched)
  }

  const createLoadDialogRef = useRef<any>(null)
  const handleCreateLoadDialogClose = () => createLoadDialogRef.current?.close()
  const handleCreateLoadDialogOpen = (row: any): void => {
    createLoadDialogRef.current?.open(row)
  }

  const freeActions: JSX.Element = (
    <>
      <Tooltip
        title={t('Fleet:currentLoads.add')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleCreateLoadDialogOpen}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:currentLoads.refresh')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => refresh()}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const cellRenderer = (column: ITableColumn, row: any): any => {
    if (column.dataKey === 'team') {
      return (
        <TeamCell
          column={column}
          row={row}
        />
      )
    }
    if (column.dataKey === 'statusName') {
      return (
        <Typography
          className={'current_loads_status'}
          color={'primary'}
        >
          {t(`Fleet:currentLoads.statuses.${row[column.dataKey]}`)}
        </Typography>
      )
    }
    if (column.dataKey === 'pickupDate') {
      return dayjs(row.stops[0].date).format(appConfig.DATE_FORMAT)
    }
    if (column.dataKey === 'deliveryDate') {
      return dayjs(row.stops[row.stops.length - 1].date).format(appConfig.DATE_FORMAT)
    }
    if (column.dataKey === 'pickupLocation') {
      return row.stops[0].countryAbbreviation + ' ' + row.stops[0].city
    }
    if (column.dataKey === 'deliveryLocation') {
      return (
        row.stops[row.stops.length - 1].countryAbbreviation +
        ' ' +
        row.stops[row.stops.length - 1].city
      )
    }
    if (column.dataKey === 'options') {
      if (row.documents?.length > 0 && getFileAlertColour(row.documents) != 'text') {
        return (
          <Tooltip title={t('Common:documentRequiresAttention')}>
            {/* @ts-ignore */}
            <WarningIcon color={getFileAlertColour(row.documents)} />
          </Tooltip>
        )
      }
    }

    return row[column.dataKey]
  }

  const ref = useRef<ITableRef>(null)

  useEffect(() => {
    if (isLoadsSuccess && !isMobile) ref.current?.open(loads, columns)
    if (isLoadsSuccess && isMobile) listRef.current?.open(loads, columns)
  }, [isLoadsSuccess, isMapView, isSearched])

  useEffect(() => {
    if (isSearchSuccess && !isMobile) ref.current?.open(searchedLoads, columns)
    if (isSearchSuccess && isMobile) listRef.current?.open(searchedLoads)
  }, [isSearchSuccess, isSearched, isMobile])

  const confirmationDialogRef = useRef<any>(null)

  const listRef = useRef<any>(null)
  useEffect(() => {
    if (isMobile) listRef.current?.open(loads)
    else ref.current?.open(loads, columns)
  }, [isMobile, loads, columns])

  const getProperComponent = (): JSX.Element => {
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          listComponent={LoadCard}
          openDialog={handleLoadDialogOpen}
        />
      )
    }
    if (isMapView) {
      return (
        <ClusteredMap
          data={loads ?? []}
          isDialogOpen={false}
          handleObjectSelect={handleLoadDialogOpen}
          type={'bookedLoads'}
          display={'deliveryLocation'}
        />
      )
    }
    return (
      <ContentTable
        ref={ref}
        freeActions={freeActions}
        cellRenderer={cellRenderer}
        openDialog={handleLoadDialogOpen}
      />
    )
  }

  const [mapRefresher, setMapRefresher] = useState<number>(0)

  if (!isLoadsSuccess && !isSearchSuccess) return <Skeleton />

  return (
    <>
      <CurrentLoadsOptionsCard
        isMapView={isMapView}
        value={searchPhrase}
        handleSearch={handleSearch}
        handleViewToggle={() => setIsMapView((prev) => !prev)}
      />
      {getProperComponent()}
      <SideDialog
        ref={createLoadDialogRef}
        title={t('Fleet:createLoadDialog.title')}
      >
        <CreateLoadDialog
          refetch={refetchLoads}
          handleClose={handleCreateLoadDialogClose}
        />
      </SideDialog>
      <SideDialog
        ref={loadDialogRef}
        title={t('Fleet:loadDialog.title')}
      >
        <LoadDialog
          refetch={refetchLoads}
          elementRef={loadDialogRef}
          mapRefresher={mapRefresher}
          handleClose={handleLoadDialogClose}
          confirmationRef={confirmationDialogRef}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default CurrentLoads
