import { useState, useEffect } from 'react'
import { t } from 'i18next'
import { FieldError } from 'react-hook-form'
import { useSearchRoleQuery } from '@features/users/usersApiSlice'
import {
  Box,
  Select,
  Chip,
  Skeleton,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  SelectChangeEvent,
  useTheme,
} from '@mui/material'
import { ITheme } from '@pkg/sokarUI'

interface RoleSelectProps {
  value: string | string[]
  onChange: (arg: any) => void
  sx?: object
  error?: FieldError
}

const RoleSelect = ({ ...props }: RoleSelectProps): JSX.Element => {
  const appTheme: ITheme = useTheme()
  const [selectedRoles, setSelectedRoles] = useState<string[]>([])
  const { data: roles } = useSearchRoleQuery(null)

  const handleChange = (event: SelectChangeEvent<typeof selectedRoles>) => {
    const {
      target: { value },
    } = event
    setSelectedRoles(typeof value === 'string' ? value.split(',') : value)
    props.onChange(typeof value === 'string' ? value.split(',') : value)
  }

  useEffect(() => {
    if (props.value) {
      setSelectedRoles(typeof props.value === 'string' ? props.value.split(',') : props.value)
    }
  }, [props.value])

  if (!roles) {
    return <Skeleton height={60} />
  }

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel id={'role-select'}>{t('Common:role')}</InputLabel>
      <Select
        multiple
        sx={props.sx}
        value={selectedRoles}
        labelId={'role-select'}
        onChange={handleChange}
        error={!!props.error}
        input={<OutlinedInput label={t('Common:role')} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={t(`Users:roles.${value}`)}
              />
            ))}
          </Box>
        )}
      >
        {roles
          .filter((role) => {
            return !['employee', 'admin', 'carrier', 'client'].includes(role.roleName)
          })
          .map((role) => (
            <MenuItem value={role.roleName}>{t(`Users:roles.${role.roleName}`)}</MenuItem>
          ))}
      </Select>
      <Box
        height={2}
        sx={{ color: appTheme.palette.error.main }}
        className={'phone_number_field_error'}
      >
        {props.error?.message}
      </Box>
    </FormControl>
  )
}

export default RoleSelect
