import { useRef, useEffect, MutableRefObject } from 'react'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { useTheme } from '@emotion/react'
import appConfig from '@/config'
import { IConfirmationDialogRef } from '@lib/ConfirmationDialog'
import ContentList, { IContentListRef } from '@lib/ContentList'
import SideDialog from '@lib/SideDialog'
import LoadDialog from './loadDialog'
import LoadCard from '@app/fleet/views/CurrentLoads/components/LoadCard'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import { ILoad } from '@pkg/loads/models'
import { ITableColumn } from '@pkg/sokarUI'
import TeamCell from '@app/fleet/views/CurrentLoads/components/TeamCell'

import { Typography, useMediaQuery } from '@mui/material'

const columns: ITableColumn[] = [
  {
    label: t('Fleet:currentLoads.number'),
    dataKey: 'externalId',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.pickupLocation'),
    dataKey: 'pickupLocation',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.pickupDate'),
    dataKey: 'pickupDate',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.deliveryLocation'),
    dataKey: 'deliveryLocation',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.deliveryDate'),
    dataKey: 'deliveryDate',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.status'),
    dataKey: 'statusName',
    isSortable: false,
  },
]

interface LoadsArchiveProps {
  data: ILoad[]
  refetch: () => void
  confirmationDialogRef: MutableRefObject<IConfirmationDialogRef | null>
}

const LoadsArchive: React.FC<LoadsArchiveProps> = ({ ...props }) => {
  const appTheme: any = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))

  const loadDialogRef = useRef<any>(null)
  const handleLoadDialogClose = () => loadDialogRef.current?.close()
  const handleLoadDialogOpen = (row: ILoad): void => {
    loadDialogRef.current?.open(row)
  }

  const cellRenderer = (column: ITableColumn, row: ILoad): any => {
    if (column.dataKey === 'team') {
      return (
        <TeamCell
          column={column}
          row={row}
        />
      )
    }
    if (column.dataKey === 'statusName') {
      return (
        <Typography
          className={'current_loads_status'}
          color={'primary'}
        >
          {t(`Fleet:currentLoads.statuses.${row[column.dataKey]}`)}
        </Typography>
      )
    }
    if (column.dataKey === 'pickupDate') {
      return dayjs(row.stops[0].date).format(appConfig.DATE_FORMAT)
    }
    if (column.dataKey === 'deliveryDate') {
      return dayjs(row.stops[row.stops.length - 1].date).format(appConfig.DATE_FORMAT)
    }
    if (column.dataKey === 'pickupLocation') {
      return row.stops[0].countryAbbreviation + ' ' + row.stops[0].city
    }
    if (column.dataKey === 'deliveryLocation') {
      return (
        row.stops[row.stops.length - 1].countryAbbreviation +
        ' ' +
        row.stops[row.stops.length - 1].city
      )
    }

    // prettier-ignore
    return (
        <Typography>
            {/* @ts-ignore */}
            {row[column.dataKey as keyof ILoad]}
        </Typography>
    )
  }

  const ref = useRef<ITableRef>(null)

  const listRef = useRef<IContentListRef>(null)
  useEffect(() => {
    if (isMobile) listRef.current?.open(props.data)
    else ref.current?.open(props.data, columns)
  }, [isMobile, props.data, columns])

  const getProperComponent = (): JSX.Element => {
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          listComponent={LoadCard}
          openDialog={handleLoadDialogOpen}
        />
      )
    }
    return (
      <ContentTable
        ref={ref}
        cellRenderer={cellRenderer}
        openDialog={handleLoadDialogOpen}
      />
    )
  }

  return (
    <>
      {getProperComponent()}
      <SideDialog
        ref={loadDialogRef}
        title={t('Fleet:loadDialog.title')}
      >
        <LoadDialog
          refetch={props.refetch}
          elementRef={loadDialogRef}
          handleClose={handleLoadDialogClose}
          confirmationRef={props.confirmationDialogRef}
        />
      </SideDialog>
    </>
  )
}

export default LoadsArchive
