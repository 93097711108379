import dayjs from 'dayjs'
import { useTheme } from '@emotion/react'
import { ILoad } from '@pkg/loads/models'
import appConfig from '@/config'

import { Box, Stack, Paper, Divider, Typography, Skeleton } from '@mui/material'

import PersonIcon from '@mui/icons-material/Person'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

interface ILoadCardProps {
  data: ILoad
  openDialog: (row: any) => void
}

const LoadCard = ({ ...props }: ILoadCardProps): JSX.Element => {
  const appTheme: any = useTheme()

  if (!props.data) return <Skeleton height={200} />

  return (
    <Paper
      className={'trailer_card_wrapper'}
      onClick={() => props.openDialog(props.data)}
      sx={{ backgroundColor: appTheme.palette.background.default }}
    >
      <Stack
        direction={'column'}
        spacing={2}
      >
        <Typography>{props.data.externalId}</Typography>
        <Divider />
        <Typography>
          {props.data.stops[0].city} {props.data.stops[0].zipCode}
        </Typography>
        <Typography>{dayjs(props.data.stops[0].date).format(appConfig.DATE_FORMAT)}</Typography>
        <ArrowDownwardIcon />
        <Typography>
          {props.data.stops[props.data.stops.length - 1].city}{' '}
          {props.data.stops[props.data.stops.length - 1].zipCode}
        </Typography>
        <Typography>
          {dayjs(props.data.stops[props.data.stops.length - 1].date).format(appConfig.DATE_FORMAT)}
        </Typography>
        <Divider />
        <Box className={'driver_card_text'}>
          <PersonIcon />
          {props.data.drivers && (
            <>
              {props.data.drivers[0].firstName} {props.data.drivers[0].lastName}
            </>
          )}
        </Box>
      </Stack>
    </Paper>
  )
}

export default LoadCard
