import { useReserveTrailerMutation } from '@/features/equipment/equipmentApiSlice'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Stack, Alert, Snackbar, Button, TextField } from '@mui/material'
import SokarLogo from '@assets/img/sokar_logo.svg'
import './style.scss'
import { useTheme } from '@emotion/react'
import { parseError } from '@/shared/utils/formatters'
import useCustomerID from '@/hooks/useCustomerID'
import { useGetCustomerIdQuery } from '@/api/apiSlice'

const ReserveFleetObjectView: React.FC = () => {
  const appTheme: any = useTheme()
  const { t } = useTranslation()
  const [driverPIN, setDriverPIN] = useState<string>('')
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [coordinates, setCoordinates] = useState<{ lat: number; lon: number }>({ lat: 0, lon: 0 })
  const [showResponse, setShowResponse] = useState<boolean>(false)
  const [responseMessage, setResponseMessage] = useState<string>('')
  const [reserveTrailer] = useReserveTrailerMutation()
  const customerTag: string = useCustomerID()
  const { data: customerId } = useGetCustomerIdQuery(customerTag, { skip: !customerTag })
  let trailerId = window.location.pathname.split('/').reverse()[0]

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoordinates({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        })
      })
    }
  }, [])

  const handleReservtion = () => {
    reserveTrailer({
      customerId: customerId ?? '',
      id: trailerId,
      driverNumber: driverPIN,
      lat: coordinates.lat,
      lon: coordinates.lon,
    })
      .unwrap()
      .then(() => {
        setResponseMessage(String(t('Fleet:responses.reservedTrailer')))
        setIsSuccess(true)
        setShowResponse(true)
      })
      .then(() => setDriverPIN(''))
      .catch((err: any) => {
        const error = parseError<any>(err.data.message)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
  }

  return (
    <Grid
      container
      spacing={0}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      className={'reserve_fleet_object_wrapper'}
    >
      <Grid
        item
        md={4}
        sm={6}
        xs={10}
        container
        direction={'column'}
        justifyContent={'center'}
        sx={{ backgroundColor: appTheme.palette.background.default }}
        className={'reserve_fleet_object_container'}
      >
        <Stack
          direction={'column'}
          spacing={2}
        >
          <img
            src={SokarLogo}
            alt={'sokar_application_logo'}
          />
          <TextField
            value={driverPIN}
            autoComplete={'off'}
            inputProps={{ maxLength: 4 }}
            onChange={(e) => setDriverPIN(e.target.value)}
            label={'PIN'}
          />
          <Button
            variant={'contained'}
            onClick={handleReservtion}
            disabled={driverPIN.length != 4}
          >
            {t('Common:claim')}
          </Button>
        </Stack>
      </Grid>
      {showResponse != undefined && (
        <Snackbar
          open={showResponse}
          autoHideDuration={6000}
          onClose={() => setShowResponse(false)}
        >
          <Alert
            variant={'filled'}
            onClose={() => setShowResponse(false)}
            severity={isSuccess ? 'success' : 'error'}
          >
            {responseMessage}
          </Alert>
        </Snackbar>
      )}
    </Grid>
  )
}

export default ReserveFleetObjectView
