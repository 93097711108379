import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { IDriver } from '@pkg/drivers/models'
import { ITheme } from '@pkg/sokarUI'
import styles from './driverSelection.module.scss'

import { Box, Paper, Autocomplete, TextField, useTheme, Typography } from '@mui/material'
import { useGetDriverQuery } from '@features/drivers/driversApiSlice'

interface DriverSelectionProps {
  companyId: string | undefined
  setDriverId: Dispatch<SetStateAction<string | undefined>>
}

const DriverSelection = ({ ...props }: DriverSelectionProps): JSX.Element => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()

  const { data: drivers } = useGetDriverQuery(
    { companyId: props.companyId ?? '' },
    { skip: !props.companyId },
  )

  const handleDriverChange = (option: DriverOption | null): void => {
    props.setDriverId(option?.value)
  }

  return (
    <Box
      component={Paper}
      className={styles.wrapper}
      sx={{ bgcolor: appTheme.palette.background.default }}
    >
      <Typography
        className={styles.title}
        sx={{ color: appTheme.palette.primary.main }}
      >
        {t('Accounting:selectDriver')}
      </Typography>
      <Autocomplete
        disabled={!props.companyId}
        noOptionsText={t('Common:noOptions')}
        options={drivers ? parseDrivers(drivers) : []}
        onChange={(e, option) => handleDriverChange(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('Accounting:driver')}
          />
        )}
      />
    </Box>
  )
}

export default DriverSelection

interface DriverOption {
  label: string
  value: string
  name: string
}

const parseDrivers = (drivers: IDriver[]): DriverOption[] => {
  return drivers.map((driver) => ({
    label: `${driver.firstName} ${driver.lastName}`,
    value: driver.id,
    name: `${driver.firstName} ${driver.lastName}`,
  }))
}
