import { useCallback, useEffect, useRef, useState } from 'react'
import { t } from 'i18next'
import dayjs from 'dayjs'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import { ITableColumn, ITheme } from '@pkg/sokarUI'
import { ILoad } from '@pkg/loads/models'
import { useGetLoadQuery, useSearchLoadsQuery } from '@features/loads/loadsApiSlice'
import useOwnUser from '@hooks/useOwnUser'
import SideDialog from '@lib/SideDialog'
import LoadDialog from './components/loadDialog'
import useRefetchWithAlert from '@hooks/useRefetchWithAlert'
import appConfig from '@/config'

import { Typography, Skeleton, useTheme, Tooltip, IconButton, useMediaQuery } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import ReplayIcon from '@mui/icons-material/Replay'
import CreateLoadDialog from './components/createLoadDialog'
import ContentList from '@/lib/ContentList'
import OfferCard from './components/offerCard'
import TransportsOptionsCard from './components/optionsCard'
import ConfirmationDialog from '@/lib/ConfirmationDialog'

const columns: ITableColumn[] = [
  {
    label: t('Market:ongoingTable.pickupLocation'),
    dataKey: 'pickupLocation',
    isSortable: false,
  },
  {
    label: t('Market:ongoingTable.pickupDate'),
    dataKey: 'pickupDate',
    isSortable: false,
  },
  {
    label: t('Market:ongoingTable.deliveryLocation'),
    dataKey: 'deliveryLocation',
    isSortable: false,
  },
  {
    label: t('Market:ongoingTable.deliveryDate'),
    dataKey: 'deliveryDate',
    isSortable: false,
  },
  {
    label: t('Market:ongoingTable.status'),
    dataKey: 'statusName',
    isSortable: true,
  },
]

const OngoingTransports: React.FC = () => {
  const appTheme: ITheme = useTheme()
  // @ts-ignore
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const tableRef = useRef<ITableRef>(null)
  const loadDialogRef = useRef<any>(null)
  const handleOpenLoadDialog = useCallback((row: ILoad) => {
    loadDialogRef.current?.open(row)
  }, [])
  const handleCloseLoadDialog = useCallback(() => {
    loadDialogRef.current?.close()
  }, [])

  const createLoadDialogRef = useRef<any>(null)
  const handleCreateLoadDialogOpen = () => {
    createLoadDialogRef.current?.open({})
  }
  const handleCreateLoadDialogClose = () => {
    createLoadDialogRef.current?.close()
  }

  const cellRenderer = (column: ITableColumn, row: ILoad): JSX.Element => {
    if (column.dataKey === 'pickupLocation') {
      return (
        <Typography>
          {row.stops[0].countryAbbreviation} {row.stops[0].city}
        </Typography>
      )
    }
    if (column.dataKey === 'pickupDate') {
      return <Typography>{dayjs(row.stops[0].date).format(appConfig.DATE_FORMAT)}</Typography>
    }
    if (column.dataKey === 'deliveryLocation') {
      return (
        <Typography>
          {row.stops[row.stops.length - 1].countryAbbreviation}{' '}
          {row.stops[row.stops.length - 1].city}
        </Typography>
      )
    }
    if (column.dataKey === 'deliveryDate') {
      return (
        <Typography>
          {dayjs(row.stops[row.stops.length - 1].date).format(appConfig.DATE_FORMAT)}
        </Typography>
      )
    }
    if (column.dataKey === 'statusName') {
      return (
        <Typography sx={{ color: appTheme.palette.primary.main }}>
          {t('Market:statuses.' + row.statusName)}
        </Typography>
      )
    }

    return <Typography>{row[column.dataKey as keyof ILoad] as string}</Typography>
  }

  const { id: userId } = useOwnUser()

  const [isSearched, setIsSearched] = useState<boolean>(false)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const handleSearchOffers = (phrase: string, isSearched: boolean) => {
    setIsSearched(isSearched)
    setSearchPhrase(phrase)
  }

  const { data: searchedOffers, isSuccess: isSearchSuccess } = useSearchLoadsQuery(
    {
      phrase: searchPhrase,
      published: false,
      archived: false,
      ownerId: userId,
      tags: 'ongoing-transport',
    },
    { skip: !isSearched },
  )

  const {
    data: ongoingTransports,
    isSuccess: isOngoingTransportsSuccess,
    refetch: refetchTransports,
  } = useGetLoadQuery(
    { ownerId: userId, published: false, tags: 'ongoing-transport', archived: false },
    { skip: !userId },
  )
  const refresh = useRefetchWithAlert(refetchTransports)

  useEffect(() => {
    if (isOngoingTransportsSuccess && !isSearched) {
      tableRef.current?.open(ongoingTransports, columns)
      listRef.current?.open(ongoingTransports)
    }
  }, [isOngoingTransportsSuccess, isSearched, ongoingTransports])

  useEffect(() => {
    if (isSearchSuccess && isSearched) {
      tableRef.current?.open(searchedOffers, columns)
      listRef.current?.open(searchedOffers)
    }
  }, [isSearchSuccess, isSearched, searchedOffers])

  useEffect(() => {
    if (isMobile) listRef.current?.open(ongoingTransports)
    else if (ongoingTransports) tableRef.current?.open(ongoingTransports, columns)
  }, [ongoingTransports, isMobile])

  const freeActions: JSX.Element = (
    <>
      <Tooltip
        title={t('Market:ongoingTable.add')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleCreateLoadDialogOpen}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:ongoingTable.refresh')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => refresh()}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const listRef = useRef<any>(null)
  const confirmationDialogRef = useRef<any>(null)

  if (!isOngoingTransportsSuccess) return <Skeleton />

  const getProperComponent = (): JSX.Element => {
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          listComponent={OfferCard}
          openDialog={handleOpenLoadDialog}
        />
      )
    }
    return (
      <ContentTable
        ref={tableRef}
        freeActions={freeActions}
        cellRenderer={cellRenderer}
        openDialog={handleOpenLoadDialog}
      />
    )
  }

  return (
    <>
      <TransportsOptionsCard
        value={searchPhrase}
        handleSearch={handleSearchOffers}
      />
      {getProperComponent()}
      <SideDialog
        ref={loadDialogRef}
        title={t('Market:ongoingLoadDialog.title')}
      >
        <LoadDialog
          refetch={refetchTransports}
          elementRef={loadDialogRef}
          confirmationRef={confirmationDialogRef}
          handleClose={handleCloseLoadDialog}
        />
      </SideDialog>
      <SideDialog
        ref={createLoadDialogRef}
        title={t('Market:createOngoingLoadDialog.title')}
      >
        <CreateLoadDialog
          refetch={refetchTransports}
          handleClose={handleCreateLoadDialogClose}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default OngoingTransports
