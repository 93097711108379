import { useState, useRef, useEffect, useCallback } from 'react'
import { t } from 'i18next'
import dayjs from 'dayjs'
import useRefetchWithAlert from '@hooks/useRefetchWithAlert'
import { useGetLoadQuery, useSearchLoadsQuery } from '@features/loads/loadsApiSlice'
import appConfig from '@/config'

import { ITableColumn, ITheme } from '@pkg/sokarUI'
import ContentList, { IContentListRef } from '@lib/ContentList'
import OfferCard from './components/OfferCard'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import CarrierLoadDialog from './components/CarrierLoadDialog'
import CarrierLoadOptionCard from './components/CarrierLoadOptionCard'
import ClusteredMap from '@lib/ClusteredMap'
import SideDialog from '@lib/SideDialog'

import {
  Chip,
  Tooltip,
  Skeleton,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import ReplayIcon from '@mui/icons-material/Replay'

const columns: ITableColumn[] = [
  {
    label: t('Market:publishedTable.equipment'),
    dataKey: 'equipment',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.weight'),
    dataKey: 'weight',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.publishedPrice'),
    dataKey: 'publishedPrice',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.pickupLocation'),
    dataKey: 'pickupLocation',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.pickupDate'),
    dataKey: 'pickupDate',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.deliveryLocation'),
    dataKey: 'deliveryLocation',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.deliveryDate'),
    dataKey: 'deliveryDate',
    isSortable: false,
  },
]

const MarketplaceView = () => {
  const appTheme: ITheme = useTheme()
  // @ts-ignore
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const [isMapView, setIsMapView] = useState<boolean>(false)

  const offerDialogRef = useRef<any>(null)
  const handleOfferDialogOpen = useCallback((row: any): void => {
    offerDialogRef.current?.open(row)
  }, [])
  const handleOfferDialogClose = useCallback((): void => {
    offerDialogRef.current?.close()
  }, [])

  const freeActions: JSX.Element = (
    <>
      <Tooltip
        title={t('Market:publishedTable.refresh')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => refresh()}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const cellRenderer = (column: any, row: any): JSX.Element => {
    if (column.dataKey === 'equipment') {
      return (
        <Chip
          color={'primary'}
          label={row.equipment}
        />
      )
    }
    if (column.dataKey === 'weight') {
      return (
        <Typography>
          {row.weight} {row.weightUnit}
        </Typography>
      )
    }
    if (column.dataKey === 'publishedPrice') {
      return (
        <Typography>
          {row.publishedPrice} {row.publishedPriceCurrency}
        </Typography>
      )
    }
    if (column.dataKey === 'pickupDate') {
      return <Typography>{dayjs(row.stops[0].date).format(appConfig.DATE_FORMAT)}</Typography>
    }
    if (column.dataKey === 'deliveryDate') {
      return (
        <Typography>
          {dayjs(row.stops[row.stops.length - 1].date).format(appConfig.DATE_FORMAT)}
        </Typography>
      )
    }
    if (column.dataKey === 'pickupLocation') {
      return <Typography>{row.stops[0].countryAbbreviation + ' ' + row.stops[0].city}</Typography>
    }
    if (column.dataKey === 'deliveryLocation') {
      return (
        <Typography>
          {row.stops[row.stops.length - 1].countryAbbreviation +
            ' ' +
            row.stops[row.stops.length - 1].city}
        </Typography>
      )
    }

    return row[column.dataKey]
  }

  const {
    data: loadOffers,
    isSuccess: isOffersSuccess,
    refetch: refetchOffers,
  } = useGetLoadQuery({ published: true, tags: 'broker-load' })
  const refresh = useRefetchWithAlert(refetchOffers)

  const [isSearched, setIsSearched] = useState<boolean>(false)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const handleSearchOffers = (phrase: string, isSearched: boolean) => {
    setIsSearched(isSearched)
    setSearchPhrase(phrase)
  }

  const { data: searchedOffers, isSuccess: isSearchOffersSuccess } = useSearchLoadsQuery(
    { phrase: searchPhrase, archived: false, published: true, tags: 'broker-load' },
    { skip: !isSearched },
  )

  const ref = useRef<ITableRef>(null)
  const listRef = useRef<IContentListRef>(null)
  useEffect(() => {
    if (isMobile) listRef.current?.open(loadOffers)
    else ref.current?.open(loadOffers, columns)
  }, [isMobile, loadOffers, columns])

  useEffect(() => {
    if (isOffersSuccess && !isSearched) ref.current?.open(loadOffers, columns)
  }, [isOffersSuccess, isMapView, isSearched])

  useEffect(() => {
    if (isSearchOffersSuccess && isSearched) ref.current?.open(searchedOffers, columns)
  }, [isSearchOffersSuccess, isSearched, searchedOffers])

  const getProperView = (): JSX.Element => {
    if (isMapView) {
      return (
        <ClusteredMap
          data={isSearched ? searchedOffers ?? [] : loadOffers ?? []}
          isDialogOpen={false}
          handleObjectSelect={handleOfferDialogOpen}
          type={'offeredLoads'}
          display={'pickupLocation'}
        />
      )
    }
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          openDialog={handleOfferDialogOpen}
          listComponent={OfferCard}
        />
      )
    }
    return (
      <ContentTable
        ref={ref}
        freeActions={freeActions}
        cellRenderer={cellRenderer}
        openDialog={handleOfferDialogOpen}
      />
    )
  }

  if (!isOffersSuccess) return <Skeleton />

  return (
    <>
      <CarrierLoadOptionCard
        isMapView={isMapView}
        value={searchPhrase}
        handleSearch={handleSearchOffers}
        handleViewToggle={() => setIsMapView((prev) => !prev)}
      />
      {getProperView()}
      <SideDialog
        ref={offerDialogRef}
        title={t('Market:carrierLoadDialog.title')}
      >
        <CarrierLoadDialog
          handleClose={handleOfferDialogClose}
          elementRef={offerDialogRef}
        />
      </SideDialog>
    </>
  )
}

export default MarketplaceView
