import { useState, useEffect, Suspense, LazyExoticComponent, lazy, MutableRefObject } from 'react'
import { t } from 'i18next'
import { Button, Tooltip, IconButton, useTheme } from '@mui/material'
import { ISideDialogChildProps, ISideDialogRef } from '@lib/SideDialog'
import { ITheme } from '@pkg/sokarUI'
import { IConfirmationDialogRef } from '@lib/ConfirmationDialog'
import { useDisableUserMutation, useEnableUserMutation } from '@features/users/usersApiSlice'
import { parseError } from '@shared/utils/formatters'
import useServerResponse from '@hooks/useServerResponse'

import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn'

type employeeDialogElements = 'info'

interface IEmployeeDialogProps extends ISideDialogChildProps {
  refetch: () => void
  handleClose: () => void
  elementRef: MutableRefObject<ISideDialogRef>
  confirmationRef: MutableRefObject<IConfirmationDialogRef | null>
}

const EmployeeDialog: React.FC<IEmployeeDialogProps> = ({ ...props }) => {
  const appTheme: ITheme = useTheme()
  const [enableUser] = useEnableUserMutation()
  const [disableUser] = useDisableUserMutation()
  const { setResponseMessage, setIsSuccess, setShowResponse } = useServerResponse()

  const handleChangeUserStatus = () => {
    const handleChangeStatus = () => {
      if (!props.data) {
        return
      }
      if (props.data.disabledAt == null) {
        disableUser(props.data.id)
          .unwrap()
          .then(() => {
            setResponseMessage(t('Management:responses.userStatusChanged'))
            setIsSuccess(true)
            setShowResponse(true)
          })
          .then(() => props.confirmationRef.current?.close())
          .then(() => props.handleClose())
          .then(() => props.refetch())
          .catch((err) => {
            const error = parseError<any>(err.message)
            setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
            setIsSuccess(false)
            setShowResponse(true)
            props.confirmationRef.current?.close()
          })
      } else {
        enableUser(props.data.id)
          .unwrap()
          .then(() => {
            setResponseMessage(t('Management:responses.userStatusChanged'))
            setIsSuccess(true)
            setShowResponse(true)
          })
          .then(() => props.confirmationRef.current?.close())
          .then(() => props.handleClose())
          .then(() => props.refetch())
          .catch((err) => {
            const error = parseError<any>(err.message)
            setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
            setIsSuccess(false)
            setShowResponse(true)
            props.confirmationRef.current?.close()
          })
      }
    }

    props.confirmationRef?.current?.open({
      title: t('Management:confirmationDialog.changeStatusTitle'),
      text: t('Management:confirmationDialog.changeStatusText'),
      actions: [
        <Button onClick={() => props.confirmationRef?.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleChangeStatus()}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const dialogActions = (
    <>
      <Tooltip
        title={
          props.data.disabledAt == null ?
            t('Management:employeeDialog.suspend')
          : t('Management:employeeDialog.unsuspend')
        }
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleChangeUserStatus()}
        >
          {props.data.disabledAt == null ?
            <DoDisturbOnIcon />
          : <DoDisturbOffIcon />}
        </IconButton>
      </Tooltip>
    </>
  )

  const [elementKey, setElementKey] = useState<employeeDialogElements>('info')
  const getProperElement = (
    key: employeeDialogElements,
  ): LazyExoticComponent<React.ComponentType<any>> => {
    switch (key) {
      default:
        return lazy(() => import('./info'))
    }
  }

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [props.data])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense fallback={<div>{t('Common:loading')}</div>}>
      <ProperComponent
        data={props.data}
        refetch={props.refetch}
      />
    </Suspense>
  )
}

export default EmployeeDialog
