import { useEffect } from 'react'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import L, { Icon, marker, LatLngExpression, latLng, LatLng } from 'leaflet'
import { useTheme } from '@emotion/react'
import { ILoad, IStop } from '@pkg/loads/models'
import dayjs from 'dayjs'
import 'leaflet-routing-machine'

import appConfig from '@/config'

interface RouteMapProps {
  offer: ILoad
}

const RouteMap = ({ offer }: RouteMapProps): JSX.Element => {
  // The component needs to be put inside a wrapper with defined width & height
  const appTheme: any = useTheme()

  const calcCenter = (): LatLngExpression => {
    let x = (offer.stops[0].lat + offer.stops[offer.stops.length - 1].lat) / 2
    let y = (offer.stops[0].lon + offer.stops[offer.stops.length - 1].lon) / 2

    return [x, y] as LatLngExpression
  }

  const mapMarker: Icon = new Icon({
    iconUrl:
      'https://magda-trans.s3.eu-central-1.amazonaws.com/static/images/geo_markers/blue_icon.svg',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
    iconSize: [37.5, 62.5],
    iconAnchor: [18, 48],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  })

  const RoutingControl = () => {
    const map: L.Map = useMap()

    useEffect(() => {
      if (!map) {
        return
      }

      const markers: LatLng[] = offer.stops.map((stop: IStop) => latLng(stop.lat, stop.lon))

      // @ts-ignore
      const routingControl = L.Routing.control({
        collapsible: true,
        hide: true,
        draggableWaypoints: false,
        addWaypoints: false,
        waypoints: markers,
        lineOptions: {
          styles: [{ color: appTheme.palette.primary.main, opacity: 1, weight: 5 }],
        },
        altLineOptions: {
          styles: [{ color: appTheme.palette.primary.main, opacity: 0.6, weight: 5 }],
        },
        showAlternatives: true,
        // @ts-ignore
        createMarker: (i: number, wp: L.Routing.Waypoint, nWps: number) => {
          const markerOptions: { title: string; icon: L.Icon<L.IconOptions> } = {
            title: '',
            icon: mapMarker,
          }

          if (i === 0 || i === nWps - 1) {
            markerOptions.title = 'Start/End'
          }

          const markerInstance = marker(wp.latLng, markerOptions)

          markerInstance.bindPopup(`
              <p>${offer.stops[i].city}</p>
              <p>${dayjs(offer.stops[i].date).format(appConfig.DATE_FORMAT)}</p>
          `)

          return markerInstance
        },
      }).addTo(map)

      return () => {
        if (map.hasLayer(routingControl)) {
          map.removeControl(routingControl)
        }
      }
    }, [offer])

    return null
  }

  return (
    <MapContainer
      center={calcCenter()}
      zoom={2}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <RoutingControl />
    </MapContainer>
  )
}

export default RouteMap
