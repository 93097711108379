import { ITheme } from '@pkg/sokarUI'
import { useTranslation } from 'react-i18next'
import styles from './card.module.scss'

import { Box, Paper, Stack, Typography, useTheme } from '@mui/material'

const EmployeeCard = ({ ...props }): JSX.Element => {
  const appTheme: ITheme = useTheme()
  const { t } = useTranslation()

  return (
    <Paper
      className={styles.wrapper}
      onClick={() => props.openDialog(props.data)}
      sx={{ backgroundColor: appTheme.palette.background.default }}
    >
      <Stack
        direction={'column'}
        spacing={2}
      >
        <Box className={styles.row}>
          <Typography>{t('Management:employeeTable.name')}</Typography>
          <Typography>{props.data?.firstName}</Typography>
        </Box>
        <Box className={styles.row}>
          <Typography>{t('Management:employeeTable.surname')}</Typography>
          <Typography>{props.data?.lastName}</Typography>
        </Box>
        <Box className={styles.row}>
          <Typography>{t('Management:employeeTable.phone')}</Typography>
          <Typography>{props.data?.phone}</Typography>
        </Box>
        <Box className={styles.row}>
          <Typography>e-mail</Typography>
          <Typography>{props.data?.email}</Typography>
        </Box>
      </Stack>
    </Paper>
  )
}

export default EmployeeCard
