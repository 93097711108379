import { useState, ChangeEvent } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { shipperInstancePublishedLoadValidator } from '@pkg/loads/validators'
import useServerResponse from '@hooks/useServerResponse'
import { initICreateCarrierLoadRequest } from '@pkg/loads/initializers'
import { useCreateLoadMutation } from '@features/loads/loadsApiSlice'
import { getRegion, parseError } from '@shared/utils/formatters'
import { ICity } from '@pkg/loads/models'
import { countryCodes } from '@pkg/countryCodes'
import CityField from '@lib/CityField'
import styles from './offerDialog.module.scss'
import useOwnUser from '@hooks/useOwnUser'

import {
  Box,
  Stack,
  TextField,
  Tooltip,
  Button,
  MenuItem,
  Typography,
  IconButton,
  useTheme,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import appConfig from '@/config'

const CreateOfferDialog = ({ ...props }): JSX.Element => {
  const { t } = useTranslation()
  const country: string | null = localStorage.getItem('country')
  const [createOffer] = useCreateLoadMutation()
  const { setShowResponse, setIsSuccess, setResponseMessage } = useServerResponse()
  const [countryCode, setCountryCode] = useState<string>(country ?? '')

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initICreateCarrierLoadRequest(),
    resolver: yupResolver(shipperInstancePublishedLoadValidator),
  })

  const { companyId } = useOwnUser()

  const { fields: stops } = useFieldArray({
    control,
    name: 'stops',
  })

  const handleCityChange = (index: number, city: ICity): void => {
    setValue(`stops.${index}.zipCode`, city.postalCode)
    setValue(`stops.${index}.lat`, city.latitude)
    setValue(`stops.${index}.lon`, city.longitude)
    setValue(`stops.${index}.city`, `${city.name}, ${city.state}`)
  }

  const onSubmit = (loadData: any): void => {
    loadData.owner = {}
    loadData.published = true
    loadData.carrierPrice = loadData.publishedPrice
    loadData.carrierPriceCurrency = loadData.publishedPriceCurrency
    loadData.shipperPrice = loadData.publishedPrice
    loadData.shipperPriceCurrency = loadData.publishedPriceCurrency
    loadData.shipperId = companyId
    loadData.tags = ['shipper-offer']
    loadData.stops[0].category = 'P'
    loadData.stops[1].category = 'D'
    for (const stop of loadData.stops) {
      if (stop.date) {
        let date = new Date(stop.date)
        const offset = date.getTimezoneOffset()
        date.setHours(date.getHours(), date.getMinutes() - offset, 0)
        stop.date = date.toISOString()
        stop.dateTo = date.toISOString()
        delete stop.time
        delete stop.timeTo
      }
    }

    createOffer(loadData)
      .unwrap()
      .then(() => {
        setResponseMessage(t('Market:response.transportRequested'))
        setIsSuccess(true)
        setShowResponse(true)
      })
      .then(() => reset())
      .then(() => props.handleClose())
      .then(() => props.refetch())
      .catch((err) => {
        const error = parseError<any>(err.data.message)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
  }

  const handleCountryChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void,
    name: string,
  ) => {
    onChange(e)
    setCountryCode(e.target.value)
    setValue(eval(name), countryCodes[e.target.value as keyof typeof countryCodes])
  }

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      direction={'column'}
      spacing={2}
    >
      {stops.map((field, index: number) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box key={field.id}>
            <Stack
              direction={'row'}
              spacing={2}
              className={styles.title}
            >
              <Typography>{t('Market:offerDialog.stopTitle', { noStop: index + 1 })}</Typography>
            </Stack>
            <Stack
              direction={'column'}
              spacing={3}
            >
              <Stack
                direction={'row'}
                spacing={2}
              >
                <Controller
                  control={control}
                  name={`stops.${index}.countryAbbreviation`}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      select
                      value={value}
                      onChange={(e) => handleCountryChange(e, onChange, `stops.${index}.country`)}
                      className={'create_load_dialog_text_field'}
                      label={t('Market:offerDialog.country')}
                      error={!!errors?.stops?.[index]?.countryAbbreviation}
                      helperText={
                        <Box height={2}>{errors?.stops?.[index]?.countryAbbreviation?.message}</Box>
                      }
                    >
                      {Object.keys(countryCodes)
                        // @ts-ignore
                        .filter((code) => countryCodes[code] === getRegion(country ?? ''))
                        .map((country) => (
                          <MenuItem value={country}>{t('Common:countries.' + country)}</MenuItem>
                        ))}
                    </TextField>
                  )}
                />
                <Controller
                  control={control}
                  name={`stops.${index}.city`}
                  render={({ field }) => (
                    <CityField
                      index={index}
                      value={field.value}
                      country={countryCode}
                      error={errors?.stops?.[index]?.city}
                      handleValueChange={handleCityChange}
                    />
                  )}
                />
              </Stack>
              <Stack
                direction={'row'}
                spacing={2}
              >
                <Controller
                  control={control}
                  name={`stops.${index}.date`}
                  render={({ field }) => (
                    <>
                      <DatePicker
                        format={appConfig.DATE_FORMAT}
                        label={t('Market:offerDialog.date')}
                        value={dayjs(field.value)}
                        onChange={field.onChange}
                        className={'create_load_dialog_text_field'}
                        slotProps={{
                          textField: {
                            error: !!errors?.stops?.[index]?.category,
                            helperText: (
                              <Box height={2}>{errors?.stops?.[index]?.category?.message}</Box>
                            ),
                          },
                        }}
                      />
                      <Stack
                        direction={'column'}
                        className={styles.dateModifier}
                      >
                        <Tooltip title={t('Market:offerDialog.addDay')}>
                          <IconButton
                            onClick={() => field.onChange(dayjs(field.value).add(1, 'day'))}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t('Market:offerDialog.subtractDay')}>
                          <IconButton
                            onClick={() => field.onChange(dayjs(field.value).subtract(1, 'day'))}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </>
                  )}
                />
              </Stack>
            </Stack>
          </Box>
        </LocalizationProvider>
      ))}
      <Stack
        direction={'row'}
        spacing={2}
      >
        <Controller
          control={control}
          name={'publishedPrice'}
          render={({ field }) => (
            <TextField
              {...field}
              type={'number'}
              className={'create_load_dialog_text_field'}
              label={t('Market:offerDialog.publishedPrice')}
              error={!!errors?.publishedPrice}
              helperText={<Box height={2}>{errors?.publishedPrice?.message}</Box>}
            />
          )}
        />
        <Controller
          control={control}
          name={'publishedPriceCurrency'}
          render={({ field }) => (
            <TextField
              {...field}
              select
              className={'create_load_dialog_text_field'}
              label={t('Market:offerDialog.publishedPriceCurrency')}
              error={!!errors?.publishedPriceCurrency}
              helperText={<Box height={2}>{errors?.publishedPriceCurrency?.message}</Box>}
            >
              {/* @ts-ignore */}
              {appConfig[getRegion(country) as keyof typeof appConfig]?.currencies?.map(
                (currency: string) => (
                  <MenuItem value={currency}>{t('Common:currencies.' + currency)}</MenuItem>
                ),
              )}
            </TextField>
          )}
        />
      </Stack>
      <Button
        variant={'contained'}
        type={'submit'}
        onClick={() => {}}
      >
        {t('Common:create')}
      </Button>
    </Stack>
  )
}

export default CreateOfferDialog
