/* eslint-disable react/display-name */
import '../styles.scss'

import BlockIcon from '@mui/icons-material/Block'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material'
import { parsePhoneNumber } from '@shared/utils/formatters'
import { t } from 'i18next'
import { forwardRef } from 'react'
import { ScrollerProps, TableBodyProps, TableComponents, TableProps } from 'react-virtuoso'

import { ICompanyColumn, IFormattedCompany } from '@/pkg/companies/models'
import { ITheme } from '@/pkg/sokarUI'

const tableHeaders: ICompanyColumn[] = [
  { label: t('Companies:table.name'), dataKey: 'name' },
  { label: t('Companies:table.address'), dataKey: 'address' },
  { label: t('Companies:table.vatId'), dataKey: 'vatId' },
  { label: t('Companies:table.regon'), dataKey: 'regon' },
  { label: t('Companies:table.phone'), dataKey: 'phone' },
  { label: t('Companies:table.email'), dataKey: 'email' },
]

export const CompaniesVirtuosoTableComponents: TableComponents<IFormattedCompany> = {
  Scroller: forwardRef<HTMLDivElement, ScrollerProps>((props, ref) => (
    <TableContainer
      component={Paper}
      {...props}
      ref={ref}
    />
  )),
  Table: (props: TableProps) => {
    const appTheme: ITheme = useTheme()
    return (
      <Table
        {...props}
        sx={{
          borderCollapse: 'separate',
          tableLayout: 'auto',
          backgroundColor: appTheme.palette.background.default,
        }}
      />
    )
  },
  TableRow: (props) => (
    <TableRow
      className='companies table-row'
      {...props}
    />
  ),
  TableBody: forwardRef<HTMLTableSectionElement, TableBodyProps>((props, ref) => (
    <TableBody
      {...props}
      ref={ref}
    />
  )),
}

export const CompaniesFixedHeaderContent = () => {
  const appTheme: ITheme = useTheme()
  return (
    <TableRow>
      {tableHeaders.map((header: ICompanyColumn) => (
        <TableCell
          key={header.dataKey}
          variant='head'
          className='companies table-header'
          sx={{
            backgroundColor: appTheme.palette.primary.main,
            color: appTheme.palette.text.light,
          }}
        >
          {header.label}
        </TableCell>
      ))}
    </TableRow>
  )
}

export const CompaniesRowContent = (
  _: number,
  row: IFormattedCompany,
  dialogRef: any,
  appTheme: any,
) => {
  const { isSuspended, phone } = row

  return (
    <>
      {tableHeaders.map(({ dataKey }: ICompanyColumn) => (
        <TableCell
          key={dataKey}
          className={`companies table-cell ${dataKey}`}
          onClick={() => dialogRef.current?.open(true, row)}
        >
          {dataKey === 'phone' ? parsePhoneNumber(phone) : row[dataKey]}
          {dataKey === 'name' && !!isSuspended && (
            <Tooltip title={t('Companies:dialog.title.suspended')}>
              <BlockIcon sx={{ color: appTheme.palette.error.main }} />
            </Tooltip>
          )}
        </TableCell>
      ))}
    </>
  )
}
