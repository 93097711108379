import { apiSlice } from 'src/api/apiSlice'
import {
  IApproveAskRequest,
  IRegisterAskRequest,
  IUserAccount,
  IUserLoginRequest,
  IUserRegisterRequest,
} from 'src/pkg/users/requests'

import { IRegisterAsk, IRole, IUser } from '@/pkg/users/models'
import { ISignInResponse } from '@/pkg/users/responses'

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation<IUser, IUserRegisterRequest>({
      query: (userDetails) => ({
        url: '/users',
        method: 'POST',
        body: userDetails,
      }),
      transformResponse: (response: { user: IUser }) => response.user,
    }),
    signIn: builder.mutation<ISignInResponse, IUserLoginRequest>({
      query: (credentials) => ({
        url: '/users/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    changePassword: builder.mutation<{}, { userId: string; credentials: any }>({
      query: ({ userId, credentials }) => ({
        url: `/users/change-password/${userId}`,
        method: 'POST',
        body: credentials,
      }),
    }),
    enableUser: builder.mutation<{}, string>({
      query: (userId) => ({
        url: `/users/enable/${userId}`,
        method: 'PUT',
      }),
    }),
    disableUser: builder.mutation<{}, string>({
      query: (userId) => ({
        url: `/users/disable/${userId}`,
        method: 'PUT',
      }),
    }),
    getUser: builder.query<IUser, string>({
      query: (userId) => `/users/${userId}`,
      transformResponse: (response: { user: IUser }) => response.user,
    }),
    searchUser: builder.query<
      IUser[],
      { companyId: string; firstName?: string; lastName?: string }
    >({
      query: ({ companyId, firstName, lastName }) => ({
        url: `/users/search`,
        params: { companyId, firstName, lastName },
      }),
      transformResponse: (response: { users: IUser[] }) => response.users,
    }),
    registerAsk: builder.mutation<{ id: string }, IRegisterAskRequest>({
      query: (askRequest) => ({
        url: '/users/register-asks',
        method: 'POST',
        body: askRequest,
      }),
    }),
    approveAsk: builder.mutation<{}, { askId: string; request: IApproveAskRequest }>({
      query: ({ askId, request }) => ({
        url: `/users/register-asks/approve/${askId}`,
        method: 'PUT',
        body: request,
      }),
    }),
    getAsk: builder.query<IRegisterAsk, string>({
      query: (askId) => `/register-asks/${askId}`,
      transformResponse: (response: { ask: IRegisterAsk }) => response.ask,
    }),
    searchAsks: builder.query<
      { totalCount: string; asks: IRegisterAsk[] },
      { approved: boolean; rejected: boolean }
    >({
      query: ({ approved, rejected }) => ({
        url: '/users/register-asks/search',
        params: { approved, rejected },
      }),
    }),
    deleteAsk: builder.mutation<{}, { askId: string; issuer: string }>({
      query: ({ askId, issuer }) => ({
        url: `/users/register-asks/reject/${askId}`,
        method: 'PUT',
        body: {
          rejectByUserId: issuer,
        },
      }),
    }),
    createRole: builder.mutation<{ role: IRole }, IRole>({
      query: (role) => ({
        url: '/users/roles',
        method: 'POST',
        body: role,
      }),
    }),
    addPermissionsToRole: builder.mutation<IRole, { roleName: string; permissions: string[] }>({
      query: ({ roleName, permissions }) => ({
        url: `/users/roles/permissions/add/${roleName}`,
        method: 'POST',
        body: { permissions },
      }),
      transformResponse: (response: { role: IRole }) => response.role,
    }),
    removePermissionsFromRole: builder.mutation<IRole, { roleName: string; permissions: string[] }>(
      {
        query: ({ roleName, permissions }) => ({
          url: `/users/roles/permissions/remove/${roleName}`,
          method: 'POST',
          body: { permissions },
        }),
        transformResponse: (response: { role: IRole }) => response.role,
      },
    ),
    addPropertiesToRole: builder.mutation<IRole, { roleName: string; properties: string[] }>({
      query: ({ roleName, properties }) => ({
        url: `/users/roles/properties/add/${roleName}`,
        method: 'POST',
        body: { permissions: properties },
      }),
      transformResponse: (response: { role: IRole }) => response.role,
    }),
    removePropertiesFromRole: builder.mutation<IRole, { roleName: string; properties: string[] }>({
      query: ({ roleName, properties }) => ({
        url: `/users/roles/properties/remove/${roleName}`,
        method: 'POST',
        body: { properties },
      }),
      transformResponse: (response: { role: IRole }) => response.role,
    }),
    getRole: builder.query<IRole, string>({
      query: (roleName) => `/users/roles/${roleName}`,
      transformResponse: (response: { role: IRole }) => response.role,
    }),
    searchRole: builder.query<IRole[], null>({
      query: () => '/users/roles/search',
      transformResponse: (response: { roles: IRole[] }) => response.roles,
    }),
    updateUserAccount: builder.mutation<IUser, { body: IUserAccount; userId: string }>({
      query: ({ body, userId }) => ({
        url: `/users/${userId}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: { user: IUser }) => response.user,
    }),
    resetPasswordRequest: builder.mutation<any, { email: string; customerId: string }>({
      query: ({ email, customerId }) => ({
        url: `/users/ask-for-reset-password`,
        method: 'POST',
        body: { email, customerId },
      }),
    }),
    provideNePasswordForPasswordReset: builder.mutation<any, any>({
      query: (body) => ({
        url: `/users/reset-password`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useRegisterMutation,
  useSignInMutation,
  useChangePasswordMutation,
  useEnableUserMutation,
  useDisableUserMutation,
  useGetUserQuery,
  useSearchUserQuery,
  useRegisterAskMutation,
  useApproveAskMutation,
  useGetAskQuery,
  useDeleteAskMutation,
  useCreateRoleMutation,
  useAddPermissionsToRoleMutation,
  useRemovePermissionsFromRoleMutation,
  useAddPropertiesToRoleMutation,
  useRemovePropertiesFromRoleMutation,
  useGetRoleQuery,
  useSearchRoleQuery,
  useUpdateUserAccountMutation,
  useSearchAsksQuery,
  useResetPasswordRequestMutation,
  useProvideNePasswordForPasswordResetMutation,
} = usersApiSlice
