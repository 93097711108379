import './style.scss'

import { useTheme } from '@emotion/react'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
} from '@mui/material'
import { ICompany } from '@pkg/companies/models'
import { useCallback, useEffect, useReducer, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  useGetCompaniesQuery,
  useSearchCompaniesQuery,
} from 'src/features/companies/companiesApiSlice'

import appConfig from '@/config'

interface CompanySearchProps {
  isDisabled: boolean
  onChange: (...event: any[]) => void
  value: any
  error?: FieldError
}

const companyReducer = (state: { id: string }, action: { type: string }): { id: string } => {
  if (action.type === 'randomizeId') {
    return {
      id: Math.random().toString(36).substring(2, 15),
    }
  }

  throw Error('Unknown action')
}

export const CompanySearch = ({ ...props }: CompanySearchProps): JSX.Element => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const [companyId, setCompanyId] = useState<string>('')
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const [isSearched, setIsSearched] = useState<boolean>(false)

  const [page, setPage] = useState<number>(1)
  const [nextPage, setNextPage] = useState<null | number>(null)
  const [lastDataLen, setLastDataLen] = useState<number>(0)

  const [state, dispatch] = useReducer(companyReducer, {
    id: Math.random().toString(36).substring(2, 15),
  })
  const {
    data: companiesSearch,
    isSuccess,
    refetch: refetchCompanies,
    isFetching: isFetchingSearch,
  } = useSearchCompaniesQuery(
    { phrase: searchPhrase, page, pageSize: appConfig.PAGE_SIZE, key: state.id },
    { skip: !isSearched },
  )

  const {
    data: companies,
    isSuccess: isCompanySuccess,
    isFetching: isFetchingCompanies,
  } = useGetCompaniesQuery(
    { page, pageSize: appConfig.PAGE_SIZE, key: state.id },
    { skip: !companyId || isSearched },
  )

  useEffect(() => {
    if (props.value) setCompanyId(props.value)
  }, [props.value])

  useEffect(() => {
    if (nextPage !== null && !isFetchingSearch && !isFetchingCompanies) {
      setPage(nextPage)
      setNextPage(null)
    }
  }, [nextPage, isFetchingSearch, isFetchingCompanies])

  //TODO this table does not handle pagination on scroll so basically the pagination returns only 1st page

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleEndReached = useCallback(() => {
    if (!isFetchingSearch && !isFetchingCompanies && companies?.length !== lastDataLen) {
      setNextPage(page + 1)
      setLastDataLen(companies?.length ?? 0)
    }
  }, [companies?.length, isFetchingCompanies, isFetchingSearch, lastDataLen, page])

  const handleSearch = (): void => {
    dispatch({ type: 'randomizeId' })
    if (!isSearched) {
      setIsSearched(true)
    } else if (isSuccess) {
      refetchCompanies()
    }
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault() // Prevent default behavior of form submission
    }
    setIsSearched(false)
  }

  return (
    <>
      <TableContainer
        component={Paper}
        className={!!props.error ? 'company_search_wrapper_error' : 'company_search_wrapper'}
        sx={{ backgroundColor: appTheme.palette.background.default }}
      >
        <Stack
          direction={'row'}
          spacing={2}
        >
          <TextField
            label={t('Common:UI.search.ownerCompany')}
            sx={{ flex: 1 }}
            disabled={props.isDisabled}
            value={searchPhrase}
            onChange={(e) => setSearchPhrase(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Tooltip title={t('Common:search')}>
            <Button
              variant={'contained'}
              onClick={handleSearch}
              disabled={searchPhrase.length === 0 || props.isDisabled}
            >
              <SearchIcon />
            </Button>
          </Tooltip>
        </Stack>
        <RadioGroup
          name={'carrier'}
          defaultValue={props.value ?? ''}
          onChange={props.onChange}
        >
          <Table className={'company_search_table'}>
            <TableBody>
              {(isFetchingCompanies || isFetchingSearch) && (
                <Box className={'company_search_loader'}>
                  <CircularProgress size={'6rem'} />
                </Box>
              )}
              {isCompanySuccess &&
                !isFetchingCompanies &&
                companies.length > 0 &&
                companies.map((company: ICompany) => (
                  <TableRow
                    key={company.id}
                    className={'company_search_table_row'}
                  >
                    <TableCell>
                      <Radio
                        value={company.id}
                        checked={company.id === companyId}
                        disabled={props.isDisabled}
                      />
                    </TableCell>
                    <TableCell>{company.name}</TableCell>
                    <TableCell>{company.vatId}</TableCell>
                  </TableRow>
                ))}
              {isSuccess &&
                !isFetchingSearch &&
                companiesSearch?.length > 0 &&
                companiesSearch.map((company: ICompany) => (
                  <TableRow
                    key={company.id}
                    className={'company_search_table_row'}
                  >
                    <TableCell>
                      <Radio
                        value={company.id}
                        disabled={props.isDisabled}
                      />
                    </TableCell>
                    <TableCell>{company.name}</TableCell>
                    <TableCell>{company.vatId}</TableCell>
                  </TableRow>
                ))}
              {isSearched && companiesSearch?.length == 0 && (
                <Box className={'company_search_no_match'}>
                  <Typography>{t('Common:noMatch')}</Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </RadioGroup>
      </TableContainer>
      <Typography sx={{ color: appTheme.palette.error.main }}>{props.error?.message}</Typography>
    </>
  )
}

export default CompanySearch
