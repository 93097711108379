import { t } from 'i18next'
import { useForm, Controller, FieldError } from 'react-hook-form'
import { Box, Stack, TextField, Button } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import PhoneNumberField from '@lib/PhoneNumberField'
import RoleSelect from '@lib/RoleSelect'
import { registerEmployeeSchema } from '@pkg/users/validators'
import { IRegisterEmployee } from '@pkg/users/requests'
import { useRegisterMutation } from '@features/users/usersApiSlice'
import useServerResponse from '@hooks/useServerResponse'
import { parseError } from '@shared/utils/formatters'
import useOwnUser from '@hooks/useOwnUser'

interface ICreateEmployeeDialogProps {
  refetch: () => void
  handleClose: () => void
}

const CreateEmployeeDialog = ({ ...props }: ICreateEmployeeDialogProps): JSX.Element => {
  const [registerEmployee] = useRegisterMutation()
  const { setResponseMessage, setIsSuccess, setShowResponse } = useServerResponse()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IRegisterEmployee>({
    resolver: yupResolver(registerEmployeeSchema),
  })

  const { companyId: ownCompanyId } = useOwnUser()
  const onSubmit = (data: IRegisterEmployee): void => {
    delete data.passwordCheck
    data.companyId = ownCompanyId
    registerEmployee(data)
      .unwrap()
      .then(() => {
        setResponseMessage(t('Management:responses.employeeRegistered'))
        setIsSuccess(true)
        setShowResponse(true)
      })
      .then(() => {
        props.refetch()
      })
      .then(() => {
        props.handleClose()
      })
      .catch((err: any) => {
        const error = parseError<any>(err.data.message)
        setResponseMessage(String(t(error.dictKey, { ...error.dependencies })))
        setIsSuccess(false)
        setShowResponse(true)
      })
  }

  return (
    <Stack
      direction={'column'}
      spacing={3}
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        control={control}
        name={'username'}
        render={({ field }) => (
          <TextField
            label={t('Management:createEmployee.username')}
            {...field}
            error={!!errors.username}
            helperText={<Box height={2}>{errors?.username?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'firstName'}
        render={({ field }) => (
          <TextField
            label={t('Management:createEmployee.name')}
            {...field}
            error={!!errors.firstName}
            helperText={<Box height={2}>{errors?.firstName?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'lastName'}
        render={({ field }) => (
          <TextField
            label={t('Management:createEmployee.surname')}
            {...field}
            error={!!errors.lastName}
            helperText={<Box height={2}>{errors?.lastName?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'email'}
        render={({ field }) => (
          <TextField
            label={'e-mail'}
            {...field}
            error={!!errors.email}
            helperText={<Box height={2}>{errors?.email?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'phone'}
        render={({ field }) => (
          <PhoneNumberField
            {...field}
            error={errors.phone}
          />
        )}
      />
      <Controller
        control={control}
        name={'roles'}
        render={({ field }) => (
          <RoleSelect
            {...field}
            error={errors.roles as FieldError}
          />
        )}
      />
      <Controller
        control={control}
        name={'password'}
        render={({ field }) => (
          <TextField
            {...field}
            type={'password'}
            label={t('Management:createEmployee.password')}
            autoComplete={'new-password'}
            error={!!errors.password}
            helperText={<Box height={2}>{errors?.password?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'passwordCheck'}
        render={({ field }) => (
          <TextField
            {...field}
            type={'password'}
            label={t('Management:createEmployee.passwordCheck')}
            autoComplete={'another-new-password'}
            error={!!errors.passwordCheck}
            helperText={<Box height={2}>{errors?.passwordCheck?.message}</Box>}
          />
        )}
      />
      <Button
        type={'submit'}
        variant={'contained'}
      >
        {t('Common:create')}
      </Button>
    </Stack>
  )
}

export default CreateEmployeeDialog
